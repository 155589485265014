
import { computed, reactive, toRefs, onMounted, onBeforeUnmount, ref } from 'vue';
import { useState } from '@/store/state';
import { useI18n } from '@/services/i18n';
import { Validators } from '@higgs/utils';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { chatApiHandler } from '@/services/chat-api-handler';
import { IOfflineSurveyRecord } from '@/models/cs/api';
import { CsStatus } from '@/models/cs/enum';
import CsActionBar from '../CsActionBar.vue';
import BaseInput from '../../base/BaseInput.vue';
import BaseButton from '../../base/BaseButton.vue';

export default {
  name: 'OfflineSurvey',
  components: {
    CsActionBar,
    BaseInput,
    BaseButton
  },
  setup() {
    const data = reactive({
      isOpen: true,
      email: '',
      message: '',
      isValid: false,
      destroy: new Subject<boolean>(),
      validator: [Validators.pattern(/^[a-zA-Z0-9]+([\_\.\-\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~][a-zA-Z0-9]+)*@[a-zA-Z0-9\+\-]+([\.][a-zA-Z0-9\+\-]+)*(\.[a-zA-Z\+][a-zA-Z\+\-]*[a-zA-Z\+]){1}$/, 'email')],
      isUploading: false,
      isClick: false,
    });

    const { $t } = useI18n();
    const { state, modules } = useState();

    function updateDataValid() {
      data.isValid = true;
    }

    const isAllowSubmit = computed(() => {
      if (state.playerInfo.isLogged) {
        updateDataValid();
      }
      return (state.playerInfo.isLogged || data.isValid) && data.message && !data.isClick;
    });

    const beforeunloadHandler = (e: any) => {
      const evt = e || window.event;
      // IE8
      if (evt) {
        evt.returnValue = $t('Common_Close_Remind');
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return $t('Common_Close_Remind');
    };

    const createBeforeunloadHandler = () => {
      fromEvent(window, 'beforeunload').pipe(takeUntil(data.destroy)).subscribe((e: any) => beforeunloadHandler(e));
    };

    const destroyedBeforeunloadHandler = () => {
      data.destroy.next(true);
    };

    onMounted(() => {
      createBeforeunloadHandler();
    });

    onBeforeUnmount(() => {
      destroyedBeforeunloadHandler();
    });

    const onSendSurvey = async () => {
      if (data.isClick || !isAllowSubmit.value) {
        return;
      }
      const request: IOfflineSurveyRecord = {
        title: 'offline survey - Offline Survey',
        email: data.email,
        content: data.message
      };
      data.isClick = true;
      const res = await chatApiHandler.submitOfflineSurvey(request);
      if (res) {
        modules.chatting.mutation.setStatus(modules.chatting.state.isSpa ? CsStatus.SpaClosed : CsStatus.LibraryClosed);
        return;
      }
      data.isClick = false;
    };

    const onCancel = () => {
      if (modules.chatting.state.isSpa) {
        modules.chatting.mutation.setStatus(CsStatus.SpaClosed);
      } else {
        const answer = window.confirm($t('Common_Cancel_Confirm').toString());
        if (answer) {
          modules.chatting.mutation.setStatus(CsStatus.LibraryClosed);
        }
      }
    };

    return {
      ...toRefs(data),
      ...toRefs(state),
      onSendSurvey,
      onCancel,
      isAllowSubmit
    };
  },
};
